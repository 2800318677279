var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mt-2",attrs:{"header":"true","header-tag":"header"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('p',{staticClass:"mb-0 d-inline-block align-middle"},[_c('b',[_vm._v(_vm._s(_vm.header.toUpperCase()))])]),_vm._v(" "),(_vm.edit === true)?_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":_vm.isEditMode},on:{"click":function($event){return _vm.onEdit()}}},[_vm._v("\n      Modifica\n    ")]):_vm._e(),_vm._v(" "),(_vm.shortcut.click)?_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":_vm.isEditMode},on:{"click":function($event){return _vm.shortcut.click()}}},[_vm._v("\n      "+_vm._s(_vm.shortcut.label)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.create === true)?_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm"},on:{"click":function($event){return _vm.onCreate()}}},[_vm._v("\n      Crea\n    ")]):_vm._e()],1),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_vm._l((_vm.fields),function(field,index){return _c('b-row',{key:index},[(_vm.hasLabels)?_c('div',{staticClass:"col-md-4 border-bottom"},[_vm._v("\n        "+_vm._s(_vm.labels[_vm.rep][field.label])+"\n      ")]):_c('div',{staticClass:"col-md-4 border-bottom"},[_vm._v("\n        "+_vm._s(field.label)+"\n      ")]),_vm._v(" "),(!_vm.isEditMode && !_vm.isCreateMode)?_c('div',{staticClass:"col-md-8 border-bottom"},[(!field.isLink)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.texts(field))}}):_c('span',_vm._l((_vm.texts(field)),function(link,index){return _c('b-link',{key:index,attrs:{"disabled":!link.id},on:{"click":function($event){return _vm.onLink(link.id)}}},[_vm._v("\n            "+_vm._s(link.caption)+"\n          ")])}),1)]):_vm._e(),_vm._v(" "),(_vm.isCreateMode)?_c('div',{staticClass:"col-md-8"},[(
            _vm.getType(field) === 'text' && field.value !== 'attributables.NINO'
          )?_c('base-input',{attrs:{"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"type":field.type || 'text',"rules":field.rules || {},"maxlength":`${field.maxlength ?? '50'}`},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isEditMode)?_c('div',{staticClass:"col-md-8"},[(
            ['text', 'tel'].includes(_vm.getType(field)) &&
            field.value !== 'attributables.NINO'
          )?_c('base-input',{attrs:{"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"type":field.type || 'text',"rules":field.rules || {},"maxlength":`${field.maxlength ?? '50'}`},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):(
            _vm.getType(field) === 'text' && field.value === 'attributables.NINO'
          )?_c('base-dynamic-input',{attrs:{"isAssistence":_vm.roles.includes('admin-assistenza'),"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"type":field.type || 'text',"controlType":"text","textcase":"upper","rules":field.rules || {}},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):(
            field.model && ['select', 'checkbox'].includes(_vm.getType(field))
          )?_c('base-select',{attrs:{"label":"","vid":field.option,"id":`input-2-${index}`,"name":`input-2-${index}`,"options":_vm.options[field.model],"rules":field.rules || {}},model:{value:(_vm.form[field.model]),callback:function ($$v) {_vm.$set(_vm.form, field.model, $$v)},expression:"form[field.model]"}}):(
            !field.option &&
            !field.options &&
            ['select', 'checkbox'].includes(_vm.getType(field))
          )?_c('base-select',{attrs:{"label":"","vid":field.value,"id":`input-1-${index}`,"name":`input-1-${index}`,"options":_vm.options[field.value],"rules":field.rules || {}},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):(
            field.option &&
            !field.options &&
            ['select', 'checkbox'].includes(_vm.getType(field))
          )?_c('base-select',{attrs:{"label":"","vid":field.option,"id":`input-2-${index}`,"name":`input-2-${index}`,"options":_vm.options[field.option],"rules":field.rules || {}},model:{value:(_vm.form[field.option]),callback:function ($$v) {_vm.$set(_vm.form, field.option, $$v)},expression:"form[field.option]"}}):(
            field.options && ['select', 'checkbox'].includes(_vm.getType(field))
          )?_c('base-select',{attrs:{"label":"","vid":field.option,"id":`input-2-${index}`,"name":`input-2-${index}`,"options":_vm.options[field.option],"rules":field.rules || {}},model:{value:(_vm.form[field.option]),callback:function ($$v) {_vm.$set(_vm.form, field.option, $$v)},expression:"form[field.option]"}}):(_vm.getType(field) === 'datepicker')?_c('base-datepicker',{attrs:{"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"rules":field.rules || {}},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):(_vm.getType(field) === 'textarea')?_c('base-textarea',{attrs:{"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"rules":field.rules || {}},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):(_vm.getType(field) === 'currency')?_c('base-currency',{attrs:{"vid":field.value,"id":`input-${index}`,"name":`${field.label}`,"rules":field.rules || {},"options":{
            currency: 'EUR',
            locale: 'it-IT',
            precision: 2,
          }},model:{value:(_vm.form[field.value]),callback:function ($$v) {_vm.$set(_vm.form, field.value, $$v)},expression:"form[field.value]"}}):_c('div',[_vm._v("Input type "+_vm._s(_vm.getType(field))+" not implemented")])],1):_vm._e()])}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditMode),expression:"isEditMode"}],staticClass:"py-2 mb-4"},[_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":invalid || !_vm.isEditMode},on:{"click":function($event){handleSubmit(_vm.onSave($event))}}},[_vm._v("\n        Salva\n      ")]),_vm._v(" "),_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":!_vm.isEditMode},on:{"click":function($event){_vm.editable = false}}},[_vm._v("\n        Annulla\n      ")])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCreateMode),expression:"isCreateMode"}],staticClass:"py-2 mb-4"},[_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":!_vm.create},on:{"click":function($event){handleSubmit(_vm.onSubmitCreate($event))}}},[_vm._v("\n        Salva\n      ")]),_vm._v(" "),_c('b-btn',{attrs:{"variant":"lisaweb","size":"sm","disabled":!_vm.create},on:{"click":function($event){_vm.creatable = false}}},[_vm._v("\n        Annulla\n      ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }